@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .bg-gradient-custom {
    background: linear-gradient(90deg, #cdffd8, #94b9ff);
  }
}

body{
  background: linear-gradient(90deg, #cdffd8, #94b9ff);
}